.ConvertInstructions {
  margin-bottom: 20px;
}

.Instruction {
  margin-bottom: 12px;
}

.Instruction__Text {
  font-size: 1.3rem;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 6px;
}

.Instruction__Images {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}

.Instruction__Images__Item {
  width: 140px;
}

.Instruction__Image__Item__Link {
  display: block;
  width: 100%;
}

.Instruction__Image__Item__Image {
  max-width: 100%;
}
