.Sitemap {
  max-width: 1185px;
  margin: 70px auto;
  display: flex;
  justify-content: space-between;
}

/* responsive */

@media (max-width: 768px) {
  .Sitemap {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Sitemap {
    max-width: 90%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .Sitemap {
    max-width: 1000px;
    flex-wrap: nowrap;
    gap: 73px;
  }
}

@media (min-width: 1280px) {
  .Sitemap {
    max-width: 1185px;
    gap: 35px;
  }
}
