.BookReviewList {
  display: flex;
  gap: 9px;
}

.BookReviewList__Item {
  cursor: pointer;
}

/* responsive */

@media (max-width: 768px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
  .BookReviewList {
    gap: 2px;
  }
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .BookReviewList {
    gap: 4px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .BookReviewList {
    gap: 9px;
  }
}
