.FilterSider {
  position: relative;
  border: 1px solid #eae8e4;
  padding: 10px;
  height: fit-content;
  min-height: 400px;
}

.FilterSider__Header {
  text-align: center;
}

.FilterSider__List__Item {
  position: relative;
  font-size: 2rem;
  font-weight: 500;
  line-height: 3.7rem;
  padding: 4px 20px;
}

.FilterSider__List__Item::after {
  content: "";
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  border-radius: 20px 0 0 20px;
  z-index: -10;
  transition: width 0.4s ease;
}

.FilterSider__List__Item:hover::after,
.FilterSider__List__Item--selected::after {
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.FilterSider__List__Item:hover,
.FilterSider__List__Item--selected {
  color: #fff;
  font-size: 2.2rem;
}

.FilterToggle {
  width: 30px;
  height: 30px;
  display: none;
}

.FilterToggle__Icon {
  position: relative;
  background-color: #000;
  width: 30px;
  height: 2px;
  display: block;
  transition: background-color 0.2s ease-out;
}

.FilterToggle__Icon::after,
.FilterToggle__Icon::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #000;
  position: absolute;
}

.FilterToggle__Icon::before {
  top: 10px;
}

.FilterToggle__Icon::after {
  top: -10px;
}
/* responsive */

@media (max-width: 1024px) {
  .FilterToggle {
    display: block;
  }
  .FilterSider {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    z-index: 20;
    overflow-y: scroll;
    height: 100vh;
    width: 220px;
  }
  .FilterSider--open {
    transform: translateX(0);
  }
  .FilterSider__List__Item {
    font-size: 1.6rem;
    line-height: 3rem;
  }
  .FilterSider__List__Item:hover,
  .FilterSider__List__Item--selected {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) and (max-width: 1919px) {
  .FilterSider {
    position: relative;
    top: 0;
    right: 0;
    transform: translateX(0);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    z-index: 20;
    overflow: initial;
    height: fit-content;
    width: auto;
  }
  .FilterToggle {
    display: none;
  }
  .FilterSider {
    display: block;
  }
  .FilterSider__List__Item {
    font-size: 1.6rem;
    line-height: 3rem;
  }
  .FilterSider__List__Item:hover,
  .FilterSider__List__Item--selected {
    font-size: 1.8rem;
  }
}
