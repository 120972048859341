.BookComments__Comments {
  position: relative;
}

.BookComments__Comments__Comment {
  margin-bottom: 20px;
}

.BookComments__Header {
  margin-bottom: 15px;
}

.BookComments__Header__Title {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 3.4rem;
  color: #265b9b;
}

.BookComments__Count {
  margin-left: 5px;
}

.BookComments__Comments__Comment:not(:last-child) {
  /* border-bottom: 1px solid #d9d9d9; */
  margin-bottom: 50px;
}

.BookComments__ViewMoreBtn {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.6rem;
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  display: block;
  width: 100%;
  height: 51px;
}
