.Stats {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.Stats__Stat {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.6rem;
  display: flex;
  align-items: center;
}

.Stats__Stat:not(:last-child)::after {
  content: "|";
  color: transparent;
  background-color: #00000070;
  width: 1px;
  height: 20px;
  margin-inline-start: 10px;
}
