.PromoCode_Container {
  border: 1px solid #f0f0f0;
  padding: 30px;
  margin-bottom: 30px;
  display: flex;
}

.PromoCode_Coupon_Img img {
  width: 70%;
}

.PromoCode_Coupon_Flex {
  display: flex;
}

.PromoCode_Coupon_Details img {
  width: 70%;
  vertical-align: bottom;
}

.PromoCode_Coupon_Details .Green_Text {
  font-size: 17px;
  color: #35b8a3;
  font-weight: bold;
  margin-bottom: 0px;
  line-height: 1rem;
}

.PromoCode_Coupon_Details .Blue_Text {
  color: #003169;
  font-size: 21px;
  font-weight: bold;
}

.PromoCode_P {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #1b1b1b;
}

.PromoCode_Coupon_Details .bookcloud_Btn {
  color: #eb9a28;
  text-decoration: underline;
  font-size: 18px;
  font-weight: bold;
}

.PromoCode_Copy_Container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.PromoCode_Copy_Container p {
  font-size: 16px;
  font-weight: bold;
  color: #1b1b1b;
}

@media (max-width: 768px) {
  .PromoCode_Container,
  .PromoCode_Copy_Container {
    display: block;
    text-align: center;
  }

  .PromoCode_Coupon_Flex {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .PromoCode_Coupon_Details .Blue_Text {
    font-size: 15px;
  }
}
