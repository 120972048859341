.SortDropdown {
  position: relative;
  width: 327px;
}

.SortDropdown__Selected {
  width: 100%;
  height: 68px;
  padding-inline: 34px;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  gap: 25px;
  cursor: pointer;
}

.SortDropdown__Selected__Text {
  color: #2d2d2d;
  width: 211px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.SortDropdown__Selected__Arrow {
  width: 19px;
  height: 11px;
  flex-shrink: 0;
}

.SortDropdown__Options {
  width: 100%;
  position: absolute;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #000;
  z-index: 10;
}

.SortDropdown__Options__Option {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.6rem;
  padding: 10px;
  background-color: #fff;
}

.SortDropdown__Options__Option:hover {
  color: #fff;
  background: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.SortDropdown__Options__Option--active {
  color: #fff;
  background: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

/* responsive */

@media (max-width: 768px) {
  .SortDropdown {
    width: 120px;
  }

  .SortDropdown__Selected {
    height: 40px;
    padding-inline: 12px;
    gap: 14px;
  }

  .SortDropdown__Selected__Text {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .SortDropdown__Selected__Arrow {
    width: 16px;
    height: 9px;
  }
  .SortDropdown__Options__Option {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1536px) {
  .SortDropdown {
    width: 220px;
  }

  .SortDropdown__Selected {
    height: 48px;
    padding-inline: 34px;
    gap: 25px;
  }

  .SortDropdown__Selected__Text {
    font-size: 1.5rem;
    line-height: 2.8rem;
  }

  .SortDropdown__Selected__Arrow {
    width: 19px;
    height: 11px;
  }
  .SortDropdown__Options__Option {
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding: 10px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .SortDropdown {
    width: 260px;
  }

  .SortDropdown__Selected {
    height: 48px;
  }
}
