.UsingBookCloud {
  width: 20%;
}

.UsingBookCloud h3 {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  line-height: 3.7rem;
  margin-bottom: 15px;
}

.UsingBookCloud p {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 100;
  line-height: 25px;
}

/* responsive */

@media (min-width: 768px) and (max-width: 1024px) {
  .UsingBookCloud {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .UsingBookCloud {
    width: 60%;
    text-align: center;
  }
}
