.PostsGrid {
  margin-bottom: 64px;
}

.PostsGrid__Grid {
  position: relative;
  /* min-height: 550px; */
  margin-bottom: 77px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 23px 10px;
}

.PostsGrid__Ad {
  grid-column: 1/-1;
  grid-row: 3;
}

@media (max-width: 500px) {
  .PostsGrid__Grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 23px 0;
  }
}

@media (min-width: 500px) and (max-width: 1280px) {
  .PostsGrid__Grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 23px 20px;
  }
}

@media (min-width: 1280px) {
  .PostsGrid__Grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 23px 10px;
  }
}
