.BookCloudPlusFooter {
  width: 23%;
}

.BookCloudPlusFooter h3 {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  line-height: 3.7rem;
  margin-bottom: 25px;
}

.BookCloudPlusFooter p {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 25px;
}

.BookCloudPlusFooter .bookcloud_register_btn {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 4px 20px;
  background-color: #eb9a28;
  margin-top: 20px;
  border-radius: 10px;
  display: block;
}

/* responsive */

@media (min-width: 768px) and (max-width: 1024px) {
  .BookCloudPlusFooter {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .BookCloudPlusFooter {
    width: 60%;
    text-align: center;
  }
}
