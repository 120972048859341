.IconWrapper {
  display: block;
  width: 40px;
}

.IconWrapper__Icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  padding-left: 15px;
  margin: 0 auto;
  display: block;
}
