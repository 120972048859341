.CircleStat {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9.6rem;
  line-height: 18rem;
  color: #fff;
}

.CircleStat--small-text {
  font-size: 3rem;
  line-height: 5.6rem;
}

/* responsive */

@media (max-width: 768px) {
  .CircleStat--small-text {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .CircleStat {
    font-size: 2rem;
    line-height: 2.3rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .CircleStat {
    font-size: 3rem;
    line-height: 3.7rem;
  }
  .CircleStat--small-text {
    font-size: 2rem;
    line-height: 4.7rem;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .CircleStat {
    font-size: 3.5rem;
    line-height: 4rem;
  }
  .CircleStat--small-text {
    font-size: 2.2rem;
    line-height: 5rem;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .CircleStat {
    font-size: 4rem;
    line-height: 5rem;
  }
  .CircleStat--small-text {
    font-size: 3rem;
    line-height: 5.6rem;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .CircleStat {
    font-size: 7.6rem;
    line-height: 14rem;
  }
  .CircleStat--small-text {
    font-size: 3rem;
    line-height: 5.6rem;
  }
}
