.ContestsBanner {
  width: 100%;
  background-color: #265b9b;
  margin-bottom: 30px;
  padding-block: 45px;
  text-align: center;
}

.ContestsBanner__Title {
  color: #fff;
  line-height: 4rem;
}

.ContestsBanner__SubTitle {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 3rem;
}
