.sub_cart_heading {
  color: #265b9b;
  font-size: 22px;
  margin-bottom: 20px;
}

.sub_row_flex {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.sub_flex_child_one {
  flex: 1;
  font-size: 18px;
  font-weight: bold;
}

.sub_flex_child_three {
  flex: 3;
  color: #265b9b;
  font-size: 18px;
  font-weight: bold;
}

.sub_flex_input {
  flex: 3;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px 18px;
}

.sub_submit_promocode {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 4px 20px;
  background-color: #eb9a28;
  margin-right: 40px;
  border-radius: 10px;
}

.sub_promocode_error_container {
  display: flex;
  margin-top: -20px;
}

.sub_promocode_error {
  font-size: 14px;
  flex: 4;
  color: red;
}

.sub_submitPayment_container {
  display: flex;
  justify-content: center;
}

.sub_submitPayment_btn {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 4px 90px;
  background-color: #eb9a28;
  border-radius: 10px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .sub_row_flex {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }

  .sub_cart_heading {
    font-size: 18px;
    text-align: center;
  }

  .sub_flex_child_one,
  .sub_flex_child_three {
    font-size: 16px;
  }

  .sub_flex_input {
    display: block;
    margin: 20px auto 20px;
  }

  .sub_submit_promocode {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
