.Author {
  position: relative;
  min-height: 100px;
  margin-bottom: 20px;
}

.Author__Details {
  display: flex;
  align-items: center;
  gap: 38px;
}

.Author__Details__Badge {
  width: 94px;
  height: 94px;
}

.Author__Details__Text__Name {
  color: #35b8a3;
  font-weight: bold;
  font-size: 2.2rem;
  margin-top: -9px;
}

.Author__Details__Text__Date {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.Author__About {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.Author__Details__Text {
  width: 75%;
}

.Author__Books__Posts__Count {
  color: #bcbcbd;
  font-size: 15px;
  font-weight: 100;
}

.Author__Details__Text__info {
  margin-top: 15px;
  font-size: 14px;
  line-height: 19px;
  text-align: justify;
}

/* responsive */

@media (max-width: 768px) {
  .Author__Details {
    align-items: flex-start;
    gap: 20px;
  }
  .Author__Details__Badge {
    width: 50px;
    height: 50px;
  }
  .Author__Details__Text__Name {
    font-size: 1.6rem;
  }
  .Author__Details__Text__Date {
    font-size: 1.3rem;
    line-height: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1536px) {
  .Author__Details {
    align-items: center;
    gap: 38px;
  }
  .Author__Details__Badge {
    width: 60px;
    height: 60px;
  }
  .Author__Details__Text__Date {
    font-size: 1.5rem;
    line-height: 2.8rem;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Author__Details__Badge {
    width: 20%;
    height: 160px;
  }
}
