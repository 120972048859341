.Link {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px 20px;
  border-radius: 4px;
}

.Link--full--w-full {
  width: 100%;
}

.Link__Plus {
  width: 16px;
  height: 16px;
}

.Link__Text {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
  width: fit-content;
}

/* responsive */

@media (max-width: 768px) {
  .Link {
    justify-content: space-between;
  }

  .Link__Text {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1536px) {
  .Link {
    justify-content: space-between;
  }

  .Link__Text {
    font-size: 1.6rem;
    line-height: 3rem;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Link__Text {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
}
