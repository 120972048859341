.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.Pagination__Pages {
  display: flex;
  gap: 5px;
  align-items: center;
}

.Pagination__Pages__Page {
  color: #c4c4c4;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.5rem;
}

.Pagination__Pages__Page:hover {
  background: linear-gradient(0deg, #35b8a3, #35b8a3),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Pagination__Pages__Page:not(:last-child)::after {
  content: "|";
  display: inline-block;
  margin-inline-start: 5px;
  background: #c4c4c4;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
}

.Pagination__Pages__Page--selected {
  background: linear-gradient(0deg, #35b8a3, #35b8a3),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.Pagination__Control:disabled {
  background-color: transparent;
}

.Pagination__arrow {
  width: 12px;
  height: 11px;
}
