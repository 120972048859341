.CommentForm {
  display: block;
  width: 100%;
}

.CommentForm__Actions {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid #dfdcd7;
}

.CommentForm__Actions__Submit,
.CommentForm__Actions__Cancel {
  flex-shrink: 0;
}
