.AuthorContainer {
  margin-bottom: 320px;
  min-height: 200px;
  position: relative;
  margin-top: 40px;
}

.Author_Books_Container {
  margin-top: 100px;
}

.Tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 60px;
}

.Tabs__Tab {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.4rem;
  color: #265b9b;
  display: flex;
  align-items: center;
}

.Tabs__Tab:not(:last-child)::after {
  content: "|";
  color: transparent;
  width: 1px;
  height: 20px;
  background-color: #d9d9d9;
  margin-inline-start: 10px;
}

.Tabs__Tab--active {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
  color: #265b9b;
}

.Posts_Count {
  margin-left: 5px;
}
/* responsive */

@media (max-width: 768px) {
  .Tabs__Tab {
    font-size: 1.4rem;
    line-height: 3rem;
  }
  .Tabs__Tab--active {
    font-size: 1.6rem;
    line-height: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Tabs__Tab {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .Tabs__Tab--active {
    font-size: 1.8rem;
    line-height: 3.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1919px) {
  .Tabs__Tab {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  .Tabs__Tab--active {
    font-size: 2.2rem;
    line-height: 3.5rem;
  }
}
