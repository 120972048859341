.Header {
  width: 100%;
  height: 580px;
  background: url("https://firebasestorage.googleapis.com/v0/b/bookcloud-dc2f2.appspot.com/o/data%2Fimages%2Fwelcome.webp?alt=media&token=4f026bfa-4672-4f13-bd06-33e6c8c67f26")
    no-repeat bottom/cover;
}

.Header--short {
  height: 580px;
  background-position: 50% 76%;
}

.Header--NavbarOnly {
  background: none;
  height: fit-content;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Header {
    height: 500px;
  }
}
