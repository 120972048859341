.Plan_Contanier {
  padding: 40px 55px;
  width: 30%;
  flex: 1;
  background-color: #f0f0f0;
  border-radius: 15px;
}

.Plan_IsMostUsed {
  padding: 40px 55px;
  width: 30%;
  flex: 1;
  background-color: #265b9b;
  border-radius: 15px;
  color: white;
}

.Plan_Contanier .Plan_Title,
.Plan_IsMostUsed .Plan_Title {
  text-align: center;
}

.Plan_Contanier .Plan_Title h1,
.Plan_IsMostUsed .Plan_Title h1 {
  font-size: 20px;
}

.Plan_Contanier .Plan_Title h6,
.Plan_IsMostUsed .Plan_Title h6 {
  font-size: 20px;
  line-height: 1px;
  margin-bottom: 35px;
}

.Plan_Contanier .Plan_Price_Right,
.Plan_IsMostUsed .Plan_Price_Right {
  margin-right: 5px;
}

.Plan_Contanier .Margin_Left,
.Plan_IsMostUsed .Margin_Left {
  margin-left: 5px;
}

.Plan_Contanier ul,
.Plan_IsMostUsed ul {
  list-style-type: square;
}

.Plan_Contanier ul li,
.Plan_IsMostUsed ul li {
  font-size: 12px;
}

.Plan_Contanier .Plan_Btn,
.Plan_IsMostUsed .Plan_Btn {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  text-align: center;
  margin: 60px auto 0;
  display: flex;
}

.Plan_Contanier .Plan_Btn:hover,
.Plan_IsMostUsed .Plan_Btn:hover {
  background: #eb9a28;
}

.Plan_IsMostUsed .MostUsedPlan {
  background-color: #eb9a28;
  color: white;
  width: 65px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
}

.Plan_After_Promo {
  color: #eb9a28;
  margin-top: -20px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .Plan_Contanier,
  .Plan_IsMostUsed {
    width: 100%;
    margin-bottom: 10px;
  }
}
