.SocialAccounts_Section {
  display: flex;
  align-items: center;
  margin-top: 25px;
  /* margin-right: 20px; */
}

.SocialAccounts__Account img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.SocialAccounts__Instagram {
  width: 42px;
  height: 42px;
  margin-inline-end: 25px;
}

.SocialAccounts__Twitter {
  width: 38px;
  height: 32px;
  margin-inline-end: 28px;
}

.SocialAccounts__Facebook {
  width: 50px;
  height: 50px;
}

/* special style for register and login pages */
.Social_Styles_For_Login_Register {
  justify-content: center;
  margin-right: 0px;
}

/* responsive */

@media (max-width: 1280px) {
  .SocialAccounts__Instagram {
    width: 36px;
    height: 36px;
    margin-inline-end: 30px;
  }

  .SocialAccounts__Twitter {
    width: 30px;
    height: 24px;
    margin-inline-end: 36px;
  }

  .SocialAccounts__Facebook {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .SocialAccounts__Instagram {
    width: 29px;
    height: 29px;
  }

  .SocialAccounts__Twitter {
    width: 25px;
    height: 23px;
  }

  .SocialAccounts__Facebook {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 768px) {
  .SocialAccounts_Section {
    width: 100%;
    margin-right: 0px;
    justify-content: center;
    text-align: center;
  }
}
