.Policy {
  margin-bottom: 6px;
}
.Policy__Title {
  color: #265b9b;
  margin-bottom: 4px;
}
.Policy__Content {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}
.Policy__Content__Refs {
  list-style-type: initial;
  padding-inline-start: 20px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}
.Policy__Content__Refs__Ref__Link {
  color: #2b6dab;
}
.Policy__Content__Refs__Ref__Link:hover {
  text-decoration: underline;
}
.Policy__Content__Link {
  color: #2b6dab;
}
.Policy__Content__Link:hover {
  text-decoration: underline;
}
