.BookPrice {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

@media (max-width: 768px) {
  .BookPrice {
    font-size: 1.2rem;
    font-weight: 900;
    line-height: 2.3rem;
  }
}

@media (min-width: 768px) {
  .BookPrice {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 2.8rem;
  }
}
