.Novel {
  display: flex;
  align-items: flex-start;
}

.Novel:not(:last-child) {
  padding-bottom: 60px;
  border-bottom: 1px solid #000000b2;
}

.Novel:not(:first-child) {
  padding-top: 60px;
}

.Novel__Author {
  flex-shrink: 0;
  margin-inline-end: 35px;
}

.Novel__Author__Name {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.Novel__Author__Name > span {
  padding-inline-end: 6px;
}

.Novel__Author__Name > a {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3rem;
  text-decoration: underline;
}

.Novel__Name {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 3rem;
}

.Novel__Content {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.Novel__Actions {
  flex-shrink: 0;
  margin-inline-start: auto;
  display: flex;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #35b8a3, #35b8a3);
}

.Novel__Actions__Action {
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 12px;
}

.Novel__Actions__Lib {
  border-left: 1px solid #000;
}

.Novel__Actions__Fav {
  background: url("../../../../../assets/icons/outlinedFavorite.svg") no-repeat
    center center;
  width: 80px;
}

.Novel__Actions__Fav--yes {
  background: url("../../../../../assets/icons/filledFavorite.svg") no-repeat
    center center;
}

/* responsive */

@media only screen and (max-width: 768px) {
  .Novel {
    flex-direction: column;
    gap: 20px;
  }
}
