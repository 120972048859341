.ContactUs {
  margin-bottom: 230px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px 30px;
}

.ContactUs__TextCol {
  color: #265b9b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ContactUs__TextCol__Text__Main {
  font-family: "FF Shamel Family";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4rem;
}

.ContactUs__TextCol__Text__Second {
  font-family: "FF Shamel Family";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4rem;
}

.ContactUs__TextCol__Text__Feat {
  font-family: "FF Shamel Family";
  font-size: 5rem;
  font-weight: 500;
  line-height: 8rem;
}

@media (max-width: 768px) {
  .ContactUs {
    grid-template-columns: repeat(1, 1fr);
  }

  .ContactUs__ImageCol {
    display: flex;
    justify-content: center;
  }
}
.AboutUs__Heading {
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 6.7rem;
  color: #265b9b;
}

.AboutUs__Text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  color: #2d2d2d;
}
.OurResources {
  margin-bottom: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.OurResources__Logo {
  display: flex;
  gap: 30px;
  align-items: center;
}

.OurResources__Logo__Dash {
  border: 1px solid #2d2d2d;
  width: 80px;
}

.OurResources__Logo__Icon {
  width: 134px;
  height: 135px;
}

.InspirationList {
  margin-bottom: 100px;
}

.InspirationList__Item {
  display: flex;
  align-items: center;
  gap: 36px;
}

.InspirationList__Item:not(:last-child) {
  margin-bottom: 49px;
}

.InspirationList__Item__Icon {
  flex-shrink: 0;
  height: 75px;
  width: 75px;
}

.InspirationList__Item__Header {
  font-size: 3rem;
  font-weight: 400;
  line-height: 5.6rem;
  background-image: linear-gradient(0deg, #35b8a3, #35b8a3),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Services {
  margin-bottom: 54px;
  padding: 70px 80px;
  border-radius: 20px;
  background-color: #265b9b;
  color: #fff;
}

.Services__Header {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.4rem;
  text-align: center;
}

.Services__List {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.Services__List__Item {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.Motto {
  margin-bottom: 135px;
  padding: 43px 260px;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.Motto__Text {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 4.5rem;
  text-align: center;
  color: #fff;
}

/* media quieries */

@media (max-width: 400px) {
  .OurResources__Logo {
    gap: 20px;
  }
  .InspirationList__Item {
    gap: 10px;
  }
  .InspirationList__Item__Icon {
    width: 100px;
    height: 100px;
  }
  .OurResources__Logo__Dash {
    width: 30px;
  }
  .OurResources__Logo__Icon {
    width: 90px;
    height: 90px;
  }
  .Services__List {
    flex-direction: column;
  }
  .Services__List__Item {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .Services {
    padding-inline: 30px;
  }
  .Motto {
    padding-inline: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Services {
    padding-inline: 30px;
  }
  .Motto {
    padding-inline: 100px;
  }
}

@media (min-width: 1024px) and (max-width: 1240px) {
  .Services {
    padding-inline: 30px;
  }
  .Motto {
    padding-inline: 120px;
  }
}

@media (min-width: 1240px) and (max-width: 1920px) {
  .Services {
    padding-inline: 60px;
  }
  .Motto {
    padding-inline: 150px;
  }
}
.BookCloudApps {
  display: flex;
  gap: 13px;
  justify-content: center;
  align-items: center;
}

.BookCloudApps__Store img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.BookCloudApps__AppStore {
  width: 72px;
  height: 70px;
}

.BookCloudApps__PlayStore {
  width: 54px;
  height: 59px;
}

@media (max-width: 1280px) {
  .BookCloudApps__AppStore {
    width: 50px;
    height: 50px;
  }

  .BookCloudApps__PlayStore {
    width: 40px;
    height: 46px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .BookCloudApps__AppStore {
    width: 60px;
    height: 60px;
  }

  .BookCloudApps__PlayStore {
    width: 46px;
    height: 50px;
  }
}
