.Star {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .Star {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 768px) {
  .Star {
    width: 20px;
    height: 20px;
  }
}
