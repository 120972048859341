.Ad {
  position: relative;
  width: 100%;
}

.Ad--1 {
  height: 541px;
  padding: 30px 26px;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.Ad--2 {
  height: 222px;
}

.Ad--mb-82 {
  margin-bottom: 82px;
}

.Ad--mb-33 {
  margin-bottom: 33px;
}

.Ad--mb-37 {
  margin-bottom: 37px;
}

.Ad--mb-0 {
  margin-bottom: 0;
}

.Ad--mb-98 {
  margin-bottom: 98px;
}

.Ad--mb-308 {
  margin-bottom: 308px;
}

.Ad--mb-664 {
  margin-bottom: 664px;
}

.Ad__Link {
  display: block;
  width: 100%;
  height: 100%;
}

.Ad__Link__Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* responsive */

@media (max-width: 768px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .Ad--vertical {
    padding: 20px 16px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Ad--vertical {
    padding: 30px 26px;
  }
}
