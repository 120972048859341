.HorizontalPosts {
  position: relative;
  margin-bottom: 50px;
  min-height: 350px;
}

.Header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.Header__Title {
  margin-inline-end: 85px;
  color: #265b9b;
  padding-top: 8px;
  border-top: 6px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.TabsList {
  display: flex;
  align-items: center;
}

.TabsList__ListItem {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.4rem;
}

.TabsList__ListItem:not(:last-child)::after {
  content: " | ";
  display: inline-block;
  padding-inline: 8px;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.TabsList__ListItem--selected {
  font-size: 2rem;
  line-height: 3.7rem;
  background-image: linear-gradient(0deg, #35b8a3, #35b8a3);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.Posts {
  width: 100%;
  display: flex;
}

.HorizontalPosts__Feedback {
  width: 50%;
  margin-inline: auto;
  text-align: center;
}

/* responsive */

@media (max-width: 768px) {
  .TabsList {
    flex-wrap: wrap;
  }

  .Header__Title {
    margin-inline-end: 10px;
  }
  .TabsList__ListItem {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
  .TabsList__ListItem--selected {
    font-size: 1.4rem;
    line-height: 2.8rem;
  }
  .TabsList__ListItem:not(:last-child)::after {
    padding-inline: 4px;
  }
}

@media (min-width: 768px) and (max-width: 1919px) {
  .Header__Title {
    margin-inline-end: 85px;
  }
  .Header__Title {
    margin-inline-end: 85px;
  }
  .TabsList__ListItem {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .TabsList__ListItem--selected {
    font-size: 2rem;
    line-height: 3.7rem;
  }
  .TabsList__ListItem:not(:last-child)::after {
    padding-inline: 8px;
  }
}
