.OTPBox__Info {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.OTPError {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
}

.OTPBox__Boxes {
  margin-bottom: 16px;
  display: flex;
  gap: 10px;
  max-width: 100%;
  justify-content: space-evenly;
}

.OTPBox__Boxes__Box {
  text-align: center;
  border: 1px solid #dfdcd7;
  border-radius: 6px;
  width: 40px;
  height: 40px;
}

.OTPBox__Boxes__Box:focus {
  border-color: #000;
}

.OTPBox__Controls {
  display: flex;
  gap: 10px;
  justify-content: center;
}
