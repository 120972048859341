.Info {
  background: #c9ddff;
  border: 1px solid #4c699b;
  padding: 10px;
}

.Info__Message {
  color: #204a8e;

  font-weight: 600;
  font-size: 1.6rem;
}
