.Search {
  max-width: 1180px;
  height: 134px;
  display: flex;
  margin: 118px auto 0;
  background: #fff;
  border-radius: 30px;
}
.SearchButton {
  width: 170px;
  border-radius: 0px 30px 30px 0px;
}

.SearchInput {
  flex-grow: 1;
  border-radius: 30px 0px 0px 30px;
  padding-inline-start: 26px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 4.5rem;
  color: #dbdbdb;
}

.SearchInput::placeholder {
  color: #dbdbdb;
}

/* responsive */

@media (max-width: 500px) {
  .Search {
    max-width: 90%;
    height: 80px;
  }
  .SearchButton {
    width: 100px;
  }
  .SearchButton img {
    width: 30px;
    height: 30px;
  }
  .SearchInput {
    font-size: 1.5rem;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .Search {
    max-width: 90%;
    height: 80px;
  }
  .SearchButton {
    width: 100px;
  }
  .SearchButton img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Search {
    max-width: 600px;
    height: 80px;
  }
  .SearchButton {
    width: 120px;
  }
  .SearchButton img {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .Search {
    max-width: 800px;
    height: 80px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .Search {
    max-width: 800px;
    height: 80px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Search {
    max-width: 630px;
    height: 100px;
  }
}
