.HeroText {
  margin-top: 30px;
  text-align: center;
  color: #fff;
}

.HeroText__Subtitle {
  font-family: Cairo;
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 6rem;
}

.HeroText__Title {
  font-size: 9rem;
  font-weight: 700;
  line-height: 10rem;
}

/* responsive */

@media (max-width: 500px) {
  .HeroText__Title {
    font-size: 3.5rem;
    line-height: 8rem;
  }
  .HeroText__Subtitle {
    font-size: 2.5rem;
    line-height: 5rem;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .HeroText__Subtitle {
    font-size: 3rem;
    line-height: 6rem;
  }
  .HeroText__Title {
    font-size: 5rem;
    line-height: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .HeroText__Title {
    font-size: 6.6rem;
    line-height: 16rem;
  }
}
