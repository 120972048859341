.Book {
  margin-left: 30px;
}

.Book__Cover {
  width: 110%;
  height: 315px;
  position: relative;
  margin-bottom: 8px;
}

.Book__Cover__Img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.Book__Cover__PriceBadgeWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
}

.Book__Cover__FooterBadges {
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  align-items: center;
}

.Book__Cover__CartBadgeWrapper {
}

.Book__Cover__FavouriteBadgeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  border-bottom-left-radius: 5px;
  background: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.Book__Cover__FavouriteBadgeWrapper img {
  padding-left: 0px;
  width: 70%;
}

.Book__Title {
  color: #265b9b;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3.4rem;
  display: block;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Book__Author {
  color: #35b8a3;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  margin-bottom: 18px;
  display: block;
}

.bookDetailsPageStyle {
}

.Book__Cover_bookDetailsPageStyle {
  height: 100%;
}

.bookcloud_label {
  background-color: #eb9a28;
  position: absolute;
  color: white;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: 0px 0px 5px;
}

/* responsive */

@media (max-width: 400px) {
  .Book__Cover {
    height: 180px;
  }
  .Book__Cover__FavouriteBadgeWrapper {
    left: 40px;
  }
  .Book__Title {
    font-size: 1.3rem;
    line-height: 3rem;
  }
  .Book__Author {
    font-size: 1.3rem;
    line-height: 2rem;
  }
}

@media (min-width: 400px) and (max-width: 768px) {
  .Book__Cover__FavouriteBadgeWrapper {
    left: 46px;
  }
  .Book__Title {
    font-size: 1.3rem;
    line-height: 3rem;
  }
  .Book__Author {
    font-size: 1.3rem;
    line-height: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1536px) {
  .Book__Cover {
    height: 315px;
  }
}

@media (max-width: 768px) {
  .Book {
    width: 100%;
  }
  .Book__Cover_bookDetailsPageStyle {
    width: 100%;
    height: 100%;
  }
}
