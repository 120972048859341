.NotificationsMenu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 250px;
  top: 70px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  padding: 10px;
  min-height: 200px;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 1;
}

.NotificationsMenu__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 16px;
}

.NotificationsMenu__Header__Title {
  font-weight: 700;
}

.NotificationsMenu__Header__Close {
  font-size: 1.8rem;
  padding: 0 10px;
}

.NotificationsMenu__Header__Close:hover {
  background-color: #7c7a7a;
  color: #fff;
}

.NotificationsMenu__List__Item {
  margin-bottom: 10px;
}

.NotificationsMenu__List__Item:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid #cecece;
}

.NotificationsMenu__List__Item {
  margin-bottom: 10px;
}

.NotificationTitle {
  font-weight: 700;
  font-size: 1.2rem;
  color: #265b9b;
}

.NotificationBody {
  font-size: 1.2rem;
  font-weight: 500;
}

.NotificationDate {
  text-align: end;
  font-size: 1.1rem;
  font-weight: 700;
  color: #7c7a7a;
}
