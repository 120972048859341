.SeeMoreBtn {
  background: rgb(27, 135, 158);
  background: linear-gradient(
    90deg,
    rgba(27, 135, 158, 1) 0%,
    rgba(42, 147, 126, 1) 100%
  );
  color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  padding: 4px 8px;
  border-radius: 4px;
  display: block;
  margin: 10px auto;
}
