.FooterNav__List {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.FooterNav__List__Item:nth-child(1)::before {
  content: "|";
  display: inline-block;
  padding-inline: 4px;
}

.FooterNav__List__Item:nth-child(2)::before,
.FooterNav__List__Item:nth-child(2)::after {
  content: "|";
  display: inline-block;
  padding-inline: 4px;
}
