.LoginForm {
  margin: 50px auto;
  max-width: 90%;
}

.LoginForm input {
  width: 100% !important;
}

.LoginForm_Headline_Container {
  display: flex;
  margin-bottom: 30px;
}

.LoginForm_Headline {
  color: #265b9b;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  margin-left: auto;
}

.LoginForm__NoAccountLink {
  display: flex;
  width: fit-content;
  font-weight: 500;
  font-size: 1.4rem;
  color: #265b9b;
  text-decoration: underline;
}

.LoginForm__InputWrapper {
  position: relative;
  margin-bottom: 20px;
}

.LoginForm__InputRow__Label {
  display: block;
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #3466a1;
}

.LoginForm__InputWrapper__Label {
  font-size: 1.6rem;
  font-weight: 500;
  color: #7b809a;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: text;
  transition: all 0.2s ease-in-out;
}

.LoginForm__Input {
  font-size: 1.6rem;
  display: block;
  width: 100%;
  border: 1px solid #d2d6da;
  border-radius: 12px;
  padding: 10px 18px;
  background-color: #f0f0f0;
}

.borderClass {
  outline: "none";
}

.LoginForm__phonenumber {
  font-size: 1.6rem;
  display: block;
  width: 100%;
  border: 1px solid #d2d6da;
  border-radius: 6px;
}

.LoginForm__Input:focus + .LoginForm__InputWrapper__Label,
.LoginForm__InputWrapper__Label--top {
  top: -20px;
  right: 20px;
  background-color: #fff;
  transform: translateY(0);
  padding-inline: 6px;
}

.LoginForm__InputWrapper__Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Password_visibility_Container {
  position: absolute;
  left: 30px;
  top: 46px;
}

.Password_visibility_Container button {
  color: #bcbcbd;
  text-decoration: underline;
  font-size: 16px;
}

.LoginForm__InputWrapper__ForgetPassword {
  font-size: 1.4rem;
  font-weight: 600;
  color: #16161a;
}

.LoginForm__InputWrapper__ForgetPassword:hover {
  text-decoration: underline;
}

.LoginForm__InputWrapper__RememberMe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.LoginForm__InputWrapper__RememberMe__Label {
  font-size: 1.6rem;
  font-weight: 400;
  color: #7b809a;
  cursor: pointer;
}

.LoginForm__InputWrapper__RememberMe__Switch {
  width: 50px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.LoginForm__InputWrapper__Switch__Input {
  display: none;
}

.LoginForm__InputWrapper__Switch__Slider {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 20;
  display: inline-block;
  background-color: #ced4da;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}

.LoginForm__InputWrapper__Switch__Slider::before {
  display: inline-block;
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ced4da;
  position: absolute;
  top: -2px;
  left: 0;
  transition: all 0.2s ease-in-out;
}

.LoginForm__InputWrapper__Switch__Input:checked
  + .LoginForm__InputWrapper__Switch__Slider::before {
  left: 50%;
  border-color: #42424a;
}

.LoginForm__InputWrapper__Switch__Input:checked
  + .LoginForm__InputWrapper__Switch__Slider {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #35b8a3, #35b8a3);
}

.LoginForm__Submit {
  position: relative;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  display: block;
  margin-top: 40px;
  width: 100%;
  height: 60px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  border-radius: 12px;
}

.LoginForm__OptionsText {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  margin-block: 20px;
}

.LoginForm__LoginOptions {
  margin-block: 20px;
  display: flex;
  gap: 20px;
}

.LoginForm__LoginOptions__Option__Btn {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: underline;
  color: #3466a1;
}

.LoginForm__Options__Option__Img {
  width: 20px;
  height: 20px;
}

.LoginForm__NoAccount {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #7f7f83;
}

.FormError {
  /* position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%; */
}
.PhoneInput {
  width: 100%;
}
/* responsive */

@media (max-width: 768px) {
  .LoginForm {
    max-width: 90%;
  }
}
