.RegisterForm {
  margin: 60px auto;
  max-width: 90%;
}

.RegisterForm__InputRow {
  margin-bottom: 20px;
}

.RegisterForm input {
  width: 100% !important;
}

.RegisterForm__InputRow__Label {
  display: block;
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #3466a1;
}

.RegisterForm__InputRow__Input {
  padding-inline: 24px;
  padding-block: 13px;
  font-size: 1.6rem;
  border: 1px solid #bcbcbd;
  display: block;
  width: 100%;
  border-radius: 12px;
  background-color: #f0f0f0;
}

.RegisterForm__InputRow__Error {
  font-size: 1.2rem;
  font-weight: 700;
  color: #ba3939;
}

.RegisterForm__InputRow__Input--Select {
  background-color: #f0f0f0;
  font-weight: 600;
  height: 60px;
}

.RegisterForm__Submit {
  position: relative;
  color: #fff;
  font-weight: bold;
  font-size: 1.8rem;
  border-radius: 12px;
  display: block;
  margin-bottom: 20px;
  margin-top: 40px;
  width: 100%;
  height: 60px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.RegisterForm__HasAccountText {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #7f7f83;
}

.RegisterForm__LoginLink {
  display: block;
  width: fit-content;
  margin-inline: auto;
  background-image: linear-gradient(195deg, #177891, #177891);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 1.4rem;
}

.FormError {
  /* position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%; */
}
