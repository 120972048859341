.PostsControls {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 50px;
}

.PostsControls__Actions {
  display: flex;
  align-items: flex-end;
  gap: 70px;
}

/* responsive */

@media (max-width: 768px) {
  .PostsControls__Actions {
    gap: 30px;
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) and (max-width: 1536px) {
  .PostsControls__Actions {
    gap: 30px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .PostsControls__Actions {
    gap: 70px;
  }
}
