.MainHeading {
  margin-bottom: 6px;
  text-align: center;
}

.CrossMark {
  border-radius:200px;
  height:200px;
  width:200px;
  background: #F8FAF5;
  margin:0 auto;
  color: #c22828;
  font-size: 100px;
  line-height: 200px;
  text-align: center;
}

.CheckMark {
  border-radius:200px;
  height:200px;
  width:200px;
  background: #F8FAF5;
  margin:0 auto;
  color: #14963f;
  font-size: 100px;
  line-height: 200px;
  text-align: center;

}

.Card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
}



.Intro {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  margin-bottom: 20px;
}

.Section {
  margin-bottom: 6px;
}

.Section__Title {
  color: #265b9b;
  margin-bottom: 4px;
}

.Section__Content {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  margin-bottom: 4px;
}

.Section__List {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.Section__List__Item::before {
  display: inline-block;
  margin-inline-end: 6px;
}

.Section__List__Item:nth-child(1)::before {
  content: "أ ) ";
}
.Section__List__Item:nth-child(2)::before {
  content: "ب ) ";
}
.Section__List__Item:nth-child(3)::before {
  content: "ج ) ";
}
.Section__List__Item:nth-child(4)::before {
  content: "د ) ";
}
.Section__List__Item:nth-child(5)::before {
  content: "هـ ) ";
}
.Section__List__Item:nth-child(6)::before {
  content: "و ) ";
}

.Section__List--numbers {
  counter-reset: list;
}

.Section__List--numbers .Section__List__Item::before {
  counter-increment: list;
  content: counter(list, number) ") ";
}
