.CartItems {
  position: relative;
  min-height: 450px;
  margin-bottom: 106px;
  border: 1px solid #ececec;
}

.CartItems__Empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.CartItems__Empty__Text {
  color: #d9d9d9;
}

.CartItems__Empty__Link {
  color: #265b9b;
  font-size: 1.6rem;
  font-weight: 700;
}

.CartItems__Table {
  width: 94%;
  margin: 53px auto 23px;
  border-collapse: collapse;
}

.CartItems__Table thead tr {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.CartItems__Table thead tr th {
  font-size: 1.8rem;
  line-height: 3.4rem;
  padding-block: 20px;
  color: #fff;
}

.CartItems__Table thead tr th:last-child {
  background-color: #ff0000;
}

.CartItems__Table tbody tr:not(:last-child) {
  border-bottom: 1px solid #cacaca;
}

.CartItems__Table tbody tr td {
  padding-block: 40px;
  text-align: center;
}

.CartItems__Table tbody tr .Cover {
  width: 20%;
  padding-inline-start: 50px;
}
.CartItems__Table tbody tr .Cover a {
  width: 111px;
  height: 166px;
  display: inline-block;
}
.CartItems__Table tbody tr .Cover a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.CartItems__Table tbody tr .Price {
  font-size: 1.8rem;
  line-height: 3.4rem;
}

.CartItems__Table tbody tr .Remove {
  position: relative;
  width: 135px;
}

.CartItems__Table tbody tr .Remove img {
  width: 50px;
  height: 50px;
}

.CartItems__Summary {
  width: 100%;
  height: 82px;
  display: flex;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #265b9b;
  color: #fff;
  font-size: 1.8rem;
  line-height: 3.4rem;
}

.CartItems__Summary__Text {
  margin-inline-start: 50px;
  width: 50%;
}

.CartItems__Summary__Total {
  width: 40%;
}

.CartItems__Summary__Complete {
  position: relative;
  width: 20%;
  height: 100%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.PromoCode_Input_Container {
  display: flex;
  padding: 20px;
  border-top: 1px solid #ececec;
  align-items: center;
}

.PromoCode_Input__Label {
  flex: 1;
  color: #265b9b;
  font-size: 20px;
  font-weight: bold;
}

.PromoCode_Input {
  flex: 3;
  font-size: 1.6rem;
  display: block;
  width: 100%;
  border: 1px solid #d2d6da;
  padding: 10px 18px;
  background-color: #f0f0f0;
}

.PromoCode_Confirm__Btn {
  flex: 1;
  color: white;
  background: #265b9b;
  font-size: 18px;
  padding: 9px 0px;
}

/* responsive */

@media (max-width: 500px) {
  .CartItems__Table thead tr th {
    font-size: 1.2rem;
    line-height: 2rem;
    padding-block: 6px;
  }
  .CartItems__Table tbody tr .Remove img {
    width: 22px;
    height: 22px;
  }
  .CartItems__Summary {
    height: 60px;
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .CartItems__Table tbody tr .Cover a {
    width: 70px;
    height: 150px;
    display: inline-block;
  }
  .CartItems__Table tbody tr .Cover {
    width: 10%;
    padding-inline-start: 10px;
  }
  .CartItems__Table tbody tr .Remove {
    width: 60px;
  }
  .CartItems__Table tbody tr .Price {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  .CartItems__Summary__Text {
    width: 40%;
    margin-inline-start: 30px;
  }
  .PromoCode_Input__Label,
  .PromoCode_Confirm__Btn {
    font-size: 12px;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .CartItems__Table thead tr th {
    font-size: 1.6rem;
    line-height: 3rem;
    padding-block: 14px;
  }
  .CartItems__Table tbody tr .Remove img {
    width: 30px;
    height: 30px;
  }
  .CartItems__Summary {
    height: 60px;
    font-size: 1.4rem;
    line-height: 3rem;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .CartItems__Table thead tr th {
    font-size: 1.8rem;
    line-height: 3.4rem;
    padding-block: 20px;
  }
  .CartItems__Table tbody tr .Remove img {
    width: 30px;
    height: 30px;
  }
  .CartItems__Summary {
    height: 82px;
    font-size: 1.8rem;
    line-height: 3.4rem;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .CartItems__Table tbody tr .Remove img {
    width: 35px;
    height: 35px;
  }
}
