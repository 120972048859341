.ContestsIntro {
  margin-bottom: 90px;
}

.ContestsIntro__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.ContestsIntro__Header__Title {
  color: #265b9b;
  padding-inline-end: 20px;
  border-bottom: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.ContestsIntro__Description {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

@media only screen and (max-width: 520px) {
  .ContestsIntro__Header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
