.ConsentForm {
  margin: 100px auto;
  max-width: 50%;
}

.FormError {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  text-align: center;
}

/* responsive */

@media (max-width: 768px) {
  .ConsentForm {
    max-width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ConsentForm {
    max-width: 80%;
  }
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .ConsentForm {
    max-width: 60%;
  }
}
