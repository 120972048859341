.AppSection {
  margin-right: 20px;
}

.AppSection__Header {
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 3.7rem;
  margin-bottom: 9px;
  margin-top: 15px;
}

/* special style for register and login pages */
.Social_Styles_For_Login_Register {
  margin: 0 auto;
  display: table;
}

/* responsive */

@media (max-width: 1024px) {
  .AppSection {
    padding-top: 30px;
    width: 100%;
  }

  .AppSection__Header {
    font-size: 1.8rem;
    line-height: 3.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .AppSection {
    padding-top: 0;
    border-top: none;
    width: auto;
  }

  .AppSection__Header {
    text-align: initial;
    font-size: 2rem;
    line-height: 3.7rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .AppSection__Header {
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .AppSection {
    margin-right: 0px;
  }

  .AppSection__Header {
    text-align: center;
  }
}
