.MobileNavigationMenu {
  position: absolute;
  right: 0;
  width: 100%;
  top: 100px;
  height: 0;
  z-index: 10;
  background: #fff;
  opacity: 0;
  transition: all 0.2s ease-in;
}

.MobileNavigationMenu--open {
  opacity: 1;
  height: 100vh;
  margin-top: 60px;
}

.MobileNavigationMenu__Nav {
  height: 100%;
}

.MobileNavigationMenu__NavList {
  overflow: scroll;
  height: 100%;
}

.MobileNavigationMenu__NavList__Item:not(:last-child) {
  border-bottom: 1px solid #c5c5c5;
}

.MobileNavigationMenu__NavList__Item__Link {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-block: 20px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 4.1rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #265b9b, #265b9b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.MobileNavigationMenu__NavList__Item__Link:hover {
  background: linear-gradient(90deg, #169fda 0%, #35b8a3 78%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
