.Navbar {
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  opacity: 0.9;
  height: 130px;
}

.Navbar__MenuBtn {
  display: none;
  width: 30px;
  height: 30px;
}

.Navbar__MenuBtn__Icon {
  position: relative;
  background-color: #fff;
  width: 30px;
  height: 2px;
  display: block;
  transition: background-color 0.2s ease-out;
}

.Navbar__MenuBtn__Icon::after,
.Navbar__MenuBtn__Icon::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #fff;
  position: absolute;
  transition: all 0.2s ease-out;
}
.Navbar__MenuBtn__Icon::after {
  top: -10px;
}
.Navbar__MenuBtn__Icon::before {
  top: 10px;
}

.Navbar__MenuBtn__Icon--open {
  background-color: transparent;
}

.Navbar__MenuBtn__Icon--open::after,
.Navbar__MenuBtn__Icon--open::before {
  top: 0;
}

.Navbar__MenuBtn__Icon--open::after {
  transform: rotate(45deg);
}

.Navbar__MenuBtn__Icon--open::before {
  transform: rotate(-45deg);
}

.Navbar__Logo {
  display: block;
  margin-inline: 240px 56px;
  margin-block: 35px;
  width: 180px;
}

.Navbar__Logo--mobile {
  display: none;
}

/* responsive */

@media (max-width: 768px) {
  .Navbar {
    height: 80px;
    justify-content: space-between;
  }
  .Navbar__Logo {
    display: none;
  }
  .Navbar__Logo--mobile {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 15px;
  }
  .Navbar__MenuBtn {
    display: block;
    margin-inline-start: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Navbar {
    height: 80px;
  }
  .Navbar__Logo {
    display: block;
    margin-inline: 30px 20px;
  }
  .Navbar__Logo--mobile {
    display: none;
  }
  .Navbar__MenuBtn {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .Navbar {
    height: 100px;
  }
  .Navbar__Logo {
    margin-inline: 60px 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .Navbar {
    height: 130px;
  }
  .Navbar__Logo {
    margin-inline: 100px 30px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Navbar__Logo {
    margin-inline: 140px 40px;
  }
}
