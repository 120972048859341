.topNavBar {
  background-color: white;
  padding: 30px 140px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .topNavBar {
    padding: 20px 20px;
  }
}

@media (max-width: 500px) {
  .topNavBar {
    padding: 17px 110px;
  }
}
