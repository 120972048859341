.NavMenu {
  height: 100%;
}
.NavMenu__List {
  display: flex;
  align-items: center;
  height: 100%;
}
.NavMenu__List__Item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #1d5955;
}

.NavMenu__List__Item:last-child {
  border-left: none;
}

.NavMenu__List__Item__Link {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 4.1rem;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
}

.NavMenu__List__Item__Link::after {
  content: "";
  display: none;
  position: absolute;
  top: 0px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #fff;
}

.NavMenu__List__Item__Link:hover::after {
  display: block;
}

.NavMenu__List__Item__Link--active {
  font-size: 2rem;
  font-weight: 800;
  line-height: 4.9rem;
}

.BookCloudAppsIcons {
  margin-inline-start: auto;
  display: flex;
  justify-content: left;
  margin-left: 140px;
}

.BookCloudPlusIcon {
  display: flex;
}

/* responsive */

@media (max-width: 768px) {
  .NavMenu {
    display: none;
  }

  .BookCloudAppsIcons {
    display: none;
  }

  .BookCloudPlusIcon {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .NavMenu {
    display: block;
  }
  .NavMenu__List__Item__Link {
    font-size: 1.1rem;
  }
  .NavMenu__List__Item__Link--active {
    font-size: 1.3rem;
  }
  .BookCloudAppsIcons {
    margin-left: 40px;
  }
  .BookCloudPlusIcon {
    margin-left: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .NavMenu__List__Item__Link {
    font-size: 1.3rem;
    min-width: 95px;
  }
  .NavMenu__List__Item__Link--active {
    font-size: 1.6rem;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .NavMenu__List__Item__Link {
    font-size: 1.8rem;
    min-width: 120px;
  }
  /* .NavMenu__List__Item__Link--active {
    font-size: 2rem;
  } */
}
