.PublishNovel {
  position: relative;
  min-height: 200px;
}

.Header {
  margin-block: 40px 70px;
  text-align: center;
}

.Header__Title {
  color: #265b9b;
}

.Header__Text {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 3.4rem;
}

.PublishNovel__Intro {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.PublishNovel__Intro__Stat {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 3.4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.PublishNovel__Form__Row {
  display: flex;
  gap: 20px;
  width: 70%;
}

.PublishNovel__Form__Row__Title {
  flex-grow: 1;
}

.PublishNovel__Form__Upload {
  display: inline-block;
  width: 200px;
  flex-shrink: 0;
  margin-top: 37px;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  font-size: 1.6rem;
  font-weight: 400;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.PublishNovel__Form__Upload__Name {
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}

/* responsive */

@media only screen and (max-width: 600px) {
  .PublishNovel__Form__Row {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .PublishNovel__Form__Row__Title {
    width: 100%;
  }
  .PublishNovel__Form__Upload {
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .PublishNovel__Form__Row {
    width: 90%;
  }
}
