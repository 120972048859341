.AuthorSummarizedStats {
  max-width: 996px;
  margin-inline: auto;
  display: grid;
  row-gap: 90px;
  grid-template-columns: repeat(3, 1fr);
}

.AuthorSummarizedStats__Stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;
}

.AuthorSummarizedStats__Stat:not(:nth-child(3n + 3)) {
  border-inline-end: 1px solid #cacaca;
}

.AuthorSummarizedStats__Stat:last-child {
  border-inline-end: none;
}

.AuthorSummarizedStats__Stat__Title {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.4rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #265b9b, #265b9b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.AuthorSummarizedStats__Stat > div {
  min-width: 98px;
  max-width: fit-content;
  height: 98px;
}

/* responsive */

@media (max-width: 768px) {
  .AuthorSummarizedStats {
    grid-template-columns: repeat(2, 1fr);
  }
  .AuthorSummarizedStats__Stat > div {
    min-width: 60px;
    height: 60px;
  }
  .AuthorSummarizedStats__Stat:not(:nth-child(3n + 3)) {
    border-inline-end: none;
  }
  .AuthorSummarizedStats__Stat:not(:nth-child(2n + 2)) {
    border-inline-end: 1px solid #cacaca;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .AuthorSummarizedStats {
    grid-template-columns: repeat(3, 1fr);
  }
  .AuthorSummarizedStats__Stat > div {
    min-width: 75px;
    height: 75px;
  }
  .AuthorSummarizedStats__Stat:not(:nth-child(2n + 2)) {
    border-inline-end: none;
  }
  .AuthorSummarizedStats__Stat:not(:nth-child(3n + 3)) {
    border-inline-end: 1px solid #cacaca;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .AuthorSummarizedStats__Stat > div {
    min-width: 98px;
    height: 98px;
  }
}
