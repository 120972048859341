.IconWrapper {
  width: 26px;
  height: 26px;
}

.InCart {
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  padding: 10px;
}

.IconWrapper__Icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* responsive */
@media (max-width: 1919px) {
  .IconWrapper {
    width: 20px;
    height: 20px;
  }
}
