.SubscriptionUserInfo_container {
  padding: 70px 0px 70px;
  margin-bottom: 60px;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.SubscriptionUserInfo_container .SubscriptionUserInfo_title {
  color: #265b9b;
  font-size: 22px;
}

.SubscriptionUserInfo_container .bookcloud_register {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 4px 20px;
  background-color: #eb9a28;
  margin-top: 20px;
  border-radius: 10px;
}

.SubscriptionUserInfo_container .subInfo_container {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.SubscriptionUserInfo_container .subInfo {
  background-color: #eb9a28;
  color: white;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  font-weight: bold;
}

.SubscriptionUserInfo_container .noSub {
  font-size: 17px;
  color: black;
  margin-left: 20px;
}

.SubscriptionUserInfo_container .removeSub {
  background-color: #e80606;
  color: white;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .SubscriptionUserInfo_container .subInfo_container {
    display: initial;
  }

  .SubscriptionUserInfo_container .subInfo,
  .SubscriptionUserInfo_container .removeSub_container {
    text-align: center;
    margin-bottom: 10px;
  }

  .SubscriptionUserInfo_container .subInfo,
  .SubscriptionUserInfo_container .removeSub,
  .SubscriptionUserInfo_container .noSub,
  .SubscriptionUserInfo_container .bookcloud_register {
    font-size: 12px;
  }

  .SubscriptionUserInfo_container .bookcloud_register {
    display: block;
    text-align: center;
  }
}
