.PostDetails {
  position: relative;
  min-height: 350px;
  margin-bottom: 41px;
}

.linkClass{
  color: green;
}

.PostDetails__Details__Title {
  color: #265b9b;
  margin-bottom: 4px;
}

.PostDetails__Details__Author {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 35px;
}

.PostDetails__Details__Author__Badge {
  width: 46px;
  height: 46px;
}
.PostDetails__Details__Author__Name {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #35b8a3, #35b8a3);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.PostDetails__PostContent {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  margin-bottom: 56px;
  white-space: pre-line;
}

.PostDetails__Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .PostDetails__Details__Title {
    font-size: 1.6rem;
  }
  .PostDetails__Footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .PostDetails__PostContent {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .PostDetails__Details__Title {
    font-size: 2rem;
  }
  .PostDetails__Footer {
    flex-direction: row;
    align-items: center;
  }
  .PostDetails__PostContent {
    margin-bottom: 56px;
  }
}
