.Error {
  background: #ffe0e0;
  border: 1px solid #a33a3a;
  padding: 10px;
}

.Error__Message {
  color: #ba3939;
  font-weight: 600;
  font-size: 1.6rem;
}
