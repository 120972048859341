.HorizontalBooks {
  width: 100%;
  min-height: 180px;
  position: relative;
}

.HorizontalBooks__Feedback {
  width: 50%;
  margin-inline: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.HorizontalBooks {
  margin-bottom: 40px;
}

.HorizontalBooks__Header {
  margin-bottom: 28px;
}

.HorizontalBooks__Header__Title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
  display: inline-block;
  padding-inline-end: 20px;
  color: #265b9b;
}
