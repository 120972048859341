.CopyBar {
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #265b9b;
  padding-block: 35px;
}

.CopyBar__Content {
  font-size: 1.5rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: #fff;
  max-width: 1185px;
  margin-inline: auto;
}

.CopyBar__Content__Right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.CopyBar__Content__Left {
  text-align: center;
}

.CopyBar__Content p {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  display: block;
}

@media (max-width: 768px) {
  .CopyBar__Content {
    flex-direction: column;
    gap: 10px;
  }

  .CopyBar__Content__Right {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .CopyBar__Content {
    max-width: 720px;
  }
  .CopyBar__Content__Right {
    flex-direction: column;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .CopyBar__Content {
    max-width: 900px;
  }
  .CopyBar__Content__Right {
    flex-direction: column;
  }
}
