.Rating__Stars {
  display: flex;
  align-items: center;
  gap: 4px;
  filter: drop-shadow(3px 4px 4px rgba(0, 0, 0, 0.2));
  margin-bottom: 4px;
}

.Rating__Numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  color: #2d2d2d;
}

.Rating__Numbers__Number {
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.8rem;
}

.Rating__Numbers__Total {
  font-size: 1.4rem;
  font-weight: 500;
}
