.Writer {
  width: 200px;
}

.Writer__ImageLink {
  width: 180px;
  height: 200px;
  display: block;
  margin: 0 auto 30px;
}

.Writer__ImageLink__Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.Writer__ImageLink__Image:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.3);
}

.Writer__Info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Writer__Info__Name {
  font-size: 1.4rem;
  font-weight: 600;
}

.Writer__Info__Name:hover {
  color: #265b9b;
}

.Writer__Info__Verified {
  width: 24px;
  height: 24px;
}
