.BookCloudPage__Title_btn_Section {
  display: flex;
  align-items: end;
}

.BookCloudPage__Title {
  flex: 1;
  color: #265b9b;
  font-size: 25px;
}

.BookCloudPage__PlansPricesBtn {
  color: #fff;
  background: #eb9a28;
  border-radius: 5px;
  padding: 1px 20px;
  font-weight: 700;
  height: 27px;
}

.BookCloudPage h3 {
  color: #265b9b;
  margin-top: 20px;
  font-weight: bold;
}

.BookCloudPage p {
  font-size: 14px;
  line-height: 25px;
}

.BookCloudPage__Note {
  margin-top: 40px;
  border: 1px solid #265b9b;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 40px;
}

.BookCloudPage__asterisk {
  color: #ff0101;
}

.Already_Subscriped {
  font-size: 14px;
}

.Already_Subscriped span {
  color: #265b9b;
  font-weight: bold;
}

.Already_Subscriped a {
  color: #eb9a28;
  font-weight: bold;
  text-decoration: underline;
}

.BookCloudPage__books_container {
}

@media (max-width: 768px) {
  .BookCloudPage__Title_btn_Section {
    display: block;
  }
}
