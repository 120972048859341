.NovelsFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 635px) {
  .NovelsFilters {
    gap: 20px;
  }
}
