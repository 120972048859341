.ContestsSection {
  margin-bottom: 30px;
}

.ContestsSection__Title {
  color: #494949;
  margin-bottom: 40px;
  border-bottom: 5px solid;
  border-image-slice: 1;
  width: fit-content;
  padding-inline-end: 20px;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.ContestsSection__List__Item:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #c3c3c7;
}

.ContestsSection__List__Item__Header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ContestName {
  color: #265b9b;
  font-size: 1.8rem;
  font-weight: 500;
}

.ContestName:hover {
  text-decoration: underline;
}

.ContestDate {
  color: #7f7f83;
  font-weight: 600;
  font-size: 1.6rem;
}

.ContestsSection__List__Item__Description {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.ContestWinner {
  display: block;
  margin-bottom: 20px;
  color: #227be5;
  text-decoration: underline;
  font-family: Cairo;
  font-size: 1.6rem;
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .ContestsSection__List__Item__Header {
    flex-direction: column;
    align-items: flex-start;
  }
}
