.Arrow {
  height: 32px;
  width: 37px;
  align-self: center;
}
.Arrow img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.Arrow--Hidden {
  display: none;
  visibility: hidden;
}

.carouselContainer {
  margin-right: -10px;
}

/* responsive */

@media (max-width: 768px) {
  .Arrow {
    height: 18px;
    width: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .Arrow {
    height: 20px;
    width: 30px;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .Arrow {
    height: 23px;
    width: 33px;
  }
}
