.Container {
  max-width: 1868px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 340px auto 340px;
}

.MainContent {
  margin-inline: 50px;
}

/* responsive */

@media (max-width: 768px) {
  .Container {
    max-width: 95%;
    grid-template-columns: 30px auto;
  }
  .Container > aside:last-child {
    display: none;
  }
  .MainContent {
    margin-inline-start: -30px;
    margin-inline-end: 0;
    /* padding-left: 30px; */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Container {
    max-width: 95%;
    grid-template-columns: 30px auto;
  }
  .Container > aside:last-child {
    display: none;
  }
  .MainContent {
    margin-inline-start: 20px;
    margin-inline-end: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .Container {
    max-width: 95%;
    grid-template-columns: 260px auto;
  }
  .Container > aside:last-child {
    display: none;
  }
  .MainContent {
    margin-inline-start: 40px;
    margin-inline-end: 0;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .Container {
    max-width: 1240px;
    grid-template-columns: 240px auto 240px;
  }
  .Container > aside:last-child {
    display: block;
  }
  .MainContent {
    margin-inline: 20px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Container {
    max-width: 1480px;
    grid-template-columns: 310px auto 310px;
  }
  .Container > aside:last-child {
    display: block;
  }
  .MainContent {
    margin-inline: 20px;
  }
}

/* modify the layout of the login page */

.Container--NoAds {
  grid-template-columns: auto;
  max-width: 100%;
  margin: 0;
}

.MainContent--NoAds {
  margin-inline: 0;
}
