.Post {
  width: 368px;
  height: 549px;
  overflow: hidden;
}

.Post__Title {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 3.4rem;
  color: #265b9b;
  margin-bottom: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Post__Author {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 15px;
}

.Post__Author__Badge {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.Post__Author__Name {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  color: #35b8a3;
}

.Post__Content {
  width: 100%;
  padding-inline-end: 48px;
  border-inline-end: 1px solid #c4c4c4;
  height: 364px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  margin-bottom: 27px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.3rem;
}

.Post:nth-child(3n + 3) .Post__Content {
  border: none;
}

.Post__Stats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding-bottom: 24px;
  border-bottom: 1px solid #c4c4c4;
  width: 80%;
}

.Post__Stats__Stat {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.6rem;
}

.Post__Stats__Stat:not(:last-child)::after {
  content: "|";
  color: transparent;
  background-color: #00000070;
  width: 1px;
  height: 15px;
  margin-inline-start: 10px;
}

/* responsive */

@media (max-width: 500px) {
  .Post {
    width: auto;
    height: auto;
  }
  .Post__Title {
    font-size: 1.6rem;
    line-height: 3rem;
  }
  .Post__Content {
    padding-inline-end: 10px;
    border: none;
    height: auto;
  }
  .Post__Stats {
    gap: 6px;
    width: 90%;
  }
}

@media (min-width: 500px) and (max-width: 1280px) {
  .Post {
    width: auto;
    height: 549px;
  }
  .Post__Title {
    font-size: 1.8rem;
    line-height: 3.4rem;
  }
  .Post__Content {
    padding-inline-end: 10px;
    height: 364px;
  }
  .Post__Stats {
    gap: 6px;
    width: 90%;
  }
  .Post:nth-child(3n + 3) .Post__Content {
    border-inline-end: 1px solid #c4c4c4;
  }
  .Post:nth-child(2n + 2) .Post__Content {
    border: none;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .Post {
    width: 220px;
  }
  .Post__Content {
    padding-inline-end: 18px;
  }
  .Post__Stats {
    gap: 6px;
    width: 100%;
  }
  .Post:nth-child(2n + 2) .Post__Content {
    border-inline-end: 1px solid #c4c4c4;
  }
  .Post:nth-child(3n + 3) .Post__Content {
    border: none;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Post {
    width: 250px;
  }
  .Post__Content {
    padding-inline-end: 28px;
  }
  .Post__Stats {
    gap: 10px;
    width: 80%;
  }
}
