.Normal_Badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  max-width: fit-content;
  width: fit-content;
  height: 66px;
  background: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.IsCart_Badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: fit-content;
  width: fit-content;
  height: 50px;
  border-bottom-left-radius: 5px;
  background: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

/* responsive */

@media (max-width: 400px) {
  .Badge {
    min-width: 40px;
    height: 38px;
  }
}

@media (min-width: 400px) {
  .Badge {
    min-width: 60px;
    height: 60px;
  }
}
