.ControlsBar {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .ControlsBar {
    width: 90%;
    margin-inline: auto;
  }
}

@media (min-width: 768px) {
  .ControlsBar {
    width: 100%;
  }
}
