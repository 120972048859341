.AuthorDetailsTables {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: flex-start;
}

.AuthorDetailsTables__Table th {
  text-align: right;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #265b9b, #265b9b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.AuthorDetailsTables__Table td {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 3rem;
}

.AuthorDetailsTables__Table th,
td {
  padding-bottom: 13px;
  padding-inline-end: 10px;
}

.AuthorDetailsTables__Table__Btn {
  background: linear-gradient(90deg, #169fda 0%, #35b8a3 78%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.6rem;
  padding-inline: 10px;
  color: #fff;
}

.AuthorDetailsTables__Table__Btn img {
  width: 10px;
  height: 10px;
}

/* responsive */

@media (max-width: 375px) {
  .AuthorDetailsTables {
    flex-wrap: wrap;
  }
  .AuthorDetailsTables__Table {
    overflow: auto;
    display: block;
    width: 240px;
    max-width: 80%;
    white-space: nowrap;
  }
}

@media (min-width: 375px) and (max-width: 768px) {
  .AuthorDetailsTables {
    flex-wrap: wrap;
  }

  .AuthorDetailsTables__Table th {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .AuthorDetailsTables__Table td {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .AuthorDetailsTables__Table th {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .AuthorDetailsTables__Table td {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
}

@media (min-width: 1024px) and (max-width: 1919px) {
  .AuthorDetailsTables__Table th {
    font-size: 1.8rem;
    line-height: 3.5rem;
  }
  .AuthorDetailsTables__Table td {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
}
