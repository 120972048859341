.AuthorDetails {
  position: relative;
  min-height: 220px;
  max-width: 941px;
  margin: 0 40px 80px auto;
  display: flex;
}

.AuthorDetails__Badge {
  display: inline-block;
  width: 122px;
  height: 115px;
  border: 3px solid #ecef69;
  border-radius: 50%;
  margin-inline-end: 62px;
}

/* responsive */

@media (max-width: 446px) {
  .AuthorDetails {
    flex-direction: column;
    margin-inline: 0;
    align-items: center;
    gap: 20px;
  }
  .AuthorDetails__Badge {
    width: 80px;
    height: 77px;
    margin-inline-end: 10px;
    flex-shrink: 0;
  }
}

@media (min-width: 446px) and (max-width: 768px) {
  .AuthorDetails {
    margin: 0 0 80px auto;
    flex-direction: row;
  }

  .AuthorDetails__Badge {
    width: 60px;
    height: 57px;
    margin-inline-end: 10px;
    flex-shrink: 0;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .AuthorDetails {
    margin: 0 0 80px auto;
  }

  .AuthorDetails__Badge {
    width: 80px;
    height: 77px;
    margin-inline-end: 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .AuthorDetails {
    margin: 0 10px 80px auto;
  }

  .AuthorDetails__Badge {
    width: 80px;
    height: 77px;
    margin-inline-end: 32px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .AuthorDetails {
    margin: 0 20px 10px auto;
  }

  .AuthorDetails__Badge {
    width: 100px;
    height: 97px;
    margin-inline-end: 62px;
  }
}
