.Comment__Writer {
  display: flex;
  align-items: center;
  gap: 27px;
  margin-bottom: 6px;
}

.Comment__Writer__Badge {
  width: 46px;
  height: 46px;
}

.Comment__Writer__Name {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  color: #35b8a3;
}

.Comment__Text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  margin-bottom: 44px;
}

.Comment__Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

.Comment__Footer__Date {
  font-size: 1.4rem;
  font-weight: 600;
  color: #7f7f83;
}
