.CancelButton {
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 12px 18px;
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  min-height: 30px;
  background: #000;
}

.CancelButton--rounded {
  border-radius: 4px;
}
