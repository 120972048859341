@media (max-width: 1024px) {
  .AdSider {
    visibility: hidden;
  }
}

@media (min-width: 1024px) {
  .AdSider {
    visibility: visible;
  }
}

@media (max-width: 768px) {
  .AdSider {
    visibility: hidden;
  }
}
