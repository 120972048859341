.PostComments {
  position: relative;
  min-height: 400px;
  margin-bottom: 60px;
}

.PostComments__Header {
  margin-bottom: 26px;
}

.PostComments__Header__Title {
  display: inline-block;
  padding-inline-end: 30px;
  padding-top: 10px;
  border-top: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.PostComments__Comments__Comment:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 17px;
}

.PostComments__ViewMoreBtn {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.6rem;
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  display: block;
  width: 100%;
  height: 51px;
}
