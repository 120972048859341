.PrimaryButton {
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 12px 18px;
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  min-height: 30px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.PrimaryButton--rounded {
  border-radius: 4px;
}
