.ShareModal {
  padding: 20px;
}

.ShareModal__Text {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 14px;
}

.ShareModalControl {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0;
}

.ShareModalControl__Input {
  border: 1px solid #dfdcd7;
  padding: 6px;
  font-size: 1.6rem;
  color: #333;
  flex-grow: 1;
}

.ShareModalControl__Button {
  flex-shrink: 0;
  border: 1px solid #dfdcd7;
  background: url("/src/assets/images/copy.png") no-repeat center/contain;
  width: 30px;
  height: 44px;
}

.ShareModalControl__Button:hover {
  background-color: #dfdcd7;
}
