.UserMenu {
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 140px;
  margin-inline-end: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  z-index: 1;
}

.UserMenu__Item {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #265b9b, #265b9b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.UserMenu__Item > * {
  padding: 10px;
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;
}

.UserMenu > *:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  -webkit-text-fill-color: #fff;
  color: #fff;
}
.UserMenu__Item--Btn {
  text-align: start;
}
