.ReviewForm {
  display: block;
  width: 100%;
}

.ReviewForm__Actions {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid #dfdcd7;
}

.ReviewForm__Actions__Submit,
.ReviewForm__Actions__Cancel {
  flex-shrink: 0;
}
