.HorizontalNovels {
  width: 100%;
  min-height: 100px;
  position: relative;
}

.HorizontalNovels__Header {
  margin-bottom: 47px;
}

.HorizontalNovels__Header__Title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
  display: inline-block;
  padding-inline-end: 20px;
  color: #265b9b;
  border-top: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.Novel {
  text-align: center;
  padding: 10px;
  width: 180px;
}

.Novel__Name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.5rem;
  margin-bottom: 10px;
}

.Novel__WriterName {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.2rem;
  color: #7f7f83;
}

.Novel__WriterName:hover {
  text-decoration: underline;
}
