.LogoSection__Logo {
  display: block;
  width: 95%;
}

.SocialAccounts {
  display: flex;
  align-items: center;
}

.SocialAccounts__Account img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.SocialAccounts__Instagram {
  width: 42px;
  height: 42px;
  margin-inline-end: 37px;
}

.SocialAccounts__Twitter {
  width: 38px;
  height: 32px;
  margin-inline-end: 46px;
}

.SocialAccounts__Facebook {
  width: 50px;
  height: 50px;
}

.AppSection__Header {
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  line-height: 3.7rem;
  margin-bottom: 9px;
}

.LogoSection {
}

/* responsive */

@media (max-width: 768px) {
  .LogoSection {
    width: 60%;
  }

  .LogoSection__Logo {
    margin-inline: auto;
  }
}

@media (min-width: 768px) {
  .LogoSection__Logo {
    margin-inline: 0;
  }
}

@media (max-width: 1280px) {
  .SocialAccounts__Instagram {
    width: 36px;
    height: 36px;
    margin-inline-end: 30px;
  }

  .SocialAccounts__Twitter {
    width: 30px;
    height: 24px;
    margin-inline-end: 36px;
  }

  .SocialAccounts__Facebook {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .SocialAccounts__Instagram {
    width: 38px;
    height: 38px;
  }

  .SocialAccounts__Twitter {
    width: 36px;
    height: 30px;
  }

  .SocialAccounts__Facebook {
    width: 46px;
    height: 46px;
  }
}

@media (max-width: 1024px) {
  .AppSection {
    padding-top: 30px;
    border-top: 1px solid #31aba5;
    width: 100%;
  }

  .AppSection__Header {
    text-align: center;
    font-size: 1.8rem;
    line-height: 3.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .AppSection {
    padding-top: 0;
    border-top: none;
    width: auto;
  }

  .AppSection__Header {
    text-align: initial;
    font-size: 2rem;
    line-height: 3.7rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .LogoSection {
    width: 45%;
  }
  .LogoSection__Logo {
    width: 86%;
  }
}
