.AuthorStats {
  margin-bottom: 50px;
}
.AuthorStats__Tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 68px;
  margin-bottom: 70px;
}

.AuthorStats__Tabs__Tab {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.4rem;
}

.AuthorStats__Tabs__Tab--active {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #265b9b, #265b9b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.AuthorStats__Content {
  position: relative;
  min-height: 400px;
}
