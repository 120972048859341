.Post {
  width: 273px;
  display: flex;
  flex-direction: column;
  height: 300px;
}

.Post__Title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #265b9b;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Post__Author {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Post__Author__Badge {
  width: 46px;
  height: 46px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.Post__Author__Name {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.8rem;
  background: linear-gradient(0deg, #35b8a3, #35b8a3),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Post__Content {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  color: #2d2d2d;
  max-height: 140px;
  overflow: hidden;
  margin-bottom: 11px;
}

.Post__Footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Post__Footer__Date {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8rem;
}

.Post__Footer__More {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3.4rem;
}

/* highlight the center post */
.rec-carousel-item-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #265b9b;
}

:global(.rec-carousel-item-visible.highlight) :local(.Post__Title) {
  color: #fff;
}

:global(.rec-carousel-item-visible.highlight) :local(.Post__Content) {
  color: #fff;
}

:global(.rec-carousel-item-visible.highlight) :local(.Post__Author__Name) {
  background: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
:global(.rec-carousel-item-visible.highlight) :local(.Post__Footer) {
  color: #fff;
}

/* responsive */

@media (max-width: 768px) {
  .Post {
    width: 90%;
  }
  .Post__Footer__More {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .Post__Footer__Date {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Post {
    width: 90%;
  }
  .Post__Footer__More {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .Post__Footer__Date {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .Post {
    width: 90%;
  }
  .Post__Footer__More {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .Post {
    width: 200px;
  }
  .Post__Footer__More {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Post {
    width: 220px;
  }
  .Post__Footer__More {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
}
