.PostActions {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  align-items: center;
}

.PostActions__Action {
  display: flex;
  align-items: center;
}

.PostActions__Action:not(:last-child)::after {
  content: "|";
  color: transparent;
  background-color: #000;
  width: 1px;
  height: 26px;
  margin-inline-start: 14px;
}
.PostActions__Action img {
  width: 28px;
  height: 28px;
  object-fit: cover;
  object-position: center;
}

/* responsive */

@media (max-width: 1024px) {
  .PostActions__Action img {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1919px) {
  .PostActions__Action img {
    width: 24px;
    height: 24px;
  }
}
