.AccountContainer {
  position: relative;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: url(../../assets/images/signUpBG.svg) no-repeat center center
    fixed;
}

.registerLoginBG {
  display: flex;
  width: 70%;
  justify-content: center;
  margin: 150px auto;
}

.AccountContainer__Form {
  width: 50%;
  flex-shrink: 0;
  padding: 20px;
  position: relative;
  background-color: #fff;
}

.leftContainerSocial {
  position: relative;
  width: 50%;
  background: linear-gradient(90deg, #1280ae 0%, #2a9382 78%);
}

.logo_img {
  display: block;
  width: 60%;
  margin: 0 auto;
}

.logo_and_social {
  position: relative;
  top: 40%;
}

.Apps_Container {
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: 50px;
}

.Register_CopyRights {
  position: absolute;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  bottom: 50px;
}

/* responsive */

@media (max-width: 768px) {
  .leftContainerSocial {
    display: none;
  }
  .AccountContainer__Form {
    width: 100%;
  }

  .Register_CopyRights {
    font-size: 14px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .leftContainerSocial {
    display: block;
  }
  .AccountContainer__Form {
    width: 50%;
  }
}
