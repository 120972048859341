.AuthenticationCorner {
  margin-inline-start: auto;
  display: flex;
  justify-content: left;
}
.AuthenticationCorner__LoginButton {
  padding-block: 15px;
  padding-inline: 18px;
  border: 1px solid #fff;
  border-radius: 12px;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: #fff;
  background-color: #eb9a28;
}

.AuthenticationCorner__Controls {
  display: flex;
  align-items: center;
  height: 50px;
}

.AuthenticationCorner__Controls__Cart {
  position: relative;
  border-left: 1px solid #b3b3b3;
  padding-left: 15px;
}

.AuthenticationCorner__Controls__Cart img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.notification_container {
  position: relative;
}

.AuthenticationCorner__Controls__Notifications {
  margin-inline-start: 19px;
}

.AuthenticationCorner__Controls__Badge {
  width: 83px;
  height: 83px;
  margin-inline-start: 23px;
  cursor: pointer;
}

/* responsive */

@media (max-width: 450px) {
  .AuthenticationCorner__Controls__Notifications {
    width: 78%;
  }

  .AuthenticationCorner__Controls__Badge {
    width: 60%;
    height: auto;
    margin-inline-start: 28px;
  }

  .AuthenticationCorner {
    margin-inline-start: initial;
    margin: auto;
  }

  .AuthenticationCorner__LoginButton {
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }
}

@media (min-width: 450px) and (max-width: 500px) {
  .AuthenticationCorner__Controls__Notifications {
    width: 70%;
  }

  .AuthenticationCorner__Controls__Badge {
    width: 57%;
    height: auto;
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .AuthenticationCorner__LoginButton {
    font-size: 2.6vw;
    padding: 5px;
    text-align: center;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .AuthenticationCorner {
    margin: 0 auto;
  }
  .AuthenticationCorner__LoginButton {
    padding-block: 3px;
    padding-inline: 5px;
    font-size: 1.4rem;
    line-height: 4.2rem;
  }

  .AuthenticationCorner__Controls__Badge {
    width: 60%;
    height: auto;
    margin-inline-start: 35px;
  }

  .AuthenticationCorner__Controls__Notifications {
    margin-inline-start: 27px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .AuthenticationCorner {
    margin-inline-end: 20px;
    width: 100%;
  }
  .AuthenticationCorner__LoginButton {
    padding-block: 3px;
    padding-inline: 5px;
    font-size: 1.4rem;
    line-height: 4.2rem;
  }
  .AuthenticationCorner__Controls__Notifications {
    margin-inline-start: 19px;
  }
  .AuthenticationCorner__Controls__Badge {
    width: 55%;
    height: auto;
    margin-inline-start: 25px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .AuthenticationCorner {
    margin-inline-end: 40px;
  }
  .AuthenticationCorner__LoginButton {
    padding-block: 6px;
    padding-inline: 8px;
    font-size: 1.6rem;
    line-height: 4.2rem;
  }

  .AuthenticationCorner__Controls__Notifications {
    width: 36px;
    height: 36px;
    margin-inline-start: 19px;
  }
  .AuthenticationCorner__Controls__Badge {
    width: 44px;
    height: 44px;
    margin-inline-start: 25px;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .AuthenticationCorner__LoginButton {
    padding-block: 8px;
    padding-inline: 10px;
    font-size: 1.8rem;
  }

  .AuthenticationCorner__Controls__Notifications {
    width: 40px;
    height: 40px;
    margin-inline-start: 19px;
  }
  .AuthenticationCorner__Controls__Badge {
    width: 47px;
    height: 47px;
    margin-inline-start: 28px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .AuthenticationCorner__LoginButton {
    padding-block: 10px;
    padding-inline: 12px;
    font-size: 2rem;
  }
  .AuthenticationCorner__Controls__Notifications {
    width: 44px;
    height: 44px;
    margin-inline-start: 19px;
  }
  .AuthenticationCorner__Controls__Badge {
    width: 48px;
    height: 48px;
    margin-inline-start: 24px;
  }
}
