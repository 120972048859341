/* cairo font with weight 500,800 */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("react-toastify/dist/ReactToastify.css");

@font-face {
  font-family: "FF Shamel Family";
  src: url("../../fonts/FFShamel/FFShamel-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "FF Shamel Family";
  src: url("../../fonts/FFShamel/FFShamel-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  padding: 0;
  margin: 0;
  min-height: 0;
  min-width: 0;
}

body {
  direction: rtl;
  font-family: "Cairo", sans-serif;
}

h1 {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 5.2rem;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 3rem;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button {
  border: none;
  outline: none;
  background: none;
  font-family: inherit;
}

button,
[role="button"] {
  cursor: pointer;
}

button:disabled,
[role="button"]:disabled {
  cursor: not-allowed;
  background: #eee;
}

input {
  background: none;
  border: none;
  outline: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

input:disabled {
  cursor: not-allowed;
  background-color: #d9d9d9 !important;
}

input,
textarea {
  font-family: inherit;
}

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.w-60 {
  width: 60%;
}

.w-90 {
  width: 90%;
}

.mb-26 {
  margin-bottom: 26px;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.mobile-navigation-open {
  max-height: 100vh;
  overflow: hidden;
}

.spinner-parent {
  position: relative;
  min-height: 80px;
  width: 100%;
}

.h-v-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled,
.disabled * {
  cursor: not-allowed;
}

.input-control {
  margin-bottom: 24px;
}

.input-control.row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.input-control__label {
  font-family: inherit;
  font-size: 1.6rem;
  display: block;
  margin-bottom: 2px;
}

.input-control__label--required::before {
  content: "*";
  display: inline-block;
  font-size: 2rem;
  margin-inline-end: 4px;
  color: #ba3939;
}

.input-control__input {
  display: block;
  width: 100%;
  border: 1px solid #dfdcd7;
  color: #7c6e65;
  font-size: 13px;
  padding: 12px 16px;
}

.input-control__input:focus {
  border-color: #000;
}

.input-control__error {
  color: #ba3939;
  font-size: 1.2rem;
  font-weight: 700;
}

.checkbox__control {
  display: flex;
  align-items: center;
  gap: 12px;
}

.checkbox {
  position: relative;
  border: 1px solid #c4c4c4;
  width: 18px !important;
  height: 18px;
  cursor: pointer;
  appearance: none;
}

.checkbox::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox:checked::after {
  opacity: 1;
}

.checkbox__label {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  cursor: pointer;
}

.input-control__input--select {
  background-color: #fff;
  font-weight: 600;
}

.mx-auto {
  margin-inline: auto;
}

.relative {
  position: relative;
}

.text-success {
  color: #228753;
  font-size: 1.6rem;
  font-weight: 600;
}

.separator {
  height: 1px;
  background-color: #e5e3e3;
  margin-block: 80px;
}

/* toastify */
.Toastify__toast {
  box-shadow: none;
  border: 2px solid;
}

.Toastify__toast--success {
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}

.Toastify__toast--error {
  border-color: #a33a3a;
}

.Toastify__toast > div:last-child {
  visibility: hidden;
}

.Toastify__toast-body div:last-child {
  font-family: "Cairo", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

.Toastify__toast--success .Toastify__toast-body div:last-child {
  color: #265b9b;
}

.Toastify__toast--success .Toastify__toast-body div:first-child svg {
  fill: #35b8a3;
}

.Toastify__toast--error .Toastify__toast-body div:last-child {
  color: #ba3939;
}

/* react carousel */
.HighlightedCarouselWrapper .rec-item-wrapper {
  justify-content: center !important;
}
.rec-item-wrapper {
  justify-content: flex-start !important;
}

/* react select */
.select {
  font-size: 1.4rem;
}

/* show more text */
.show-more-less-clickable {
  color: #265b9b;
}
