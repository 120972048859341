.AddResourceHeader {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 22px;
}

.AddResourceHeader__Img {
  width: 32px;
  height: 32px;
}
