.SubscriptionInfoModal_container {
  padding: 20px;
}

.SubscriptionInfoModal_container .subBold {
  font-size: 16px;
  font-weight: bold;
}

.SubscriptionInfoModal_container .subFlex {
  align-items: baseline;
  display: flex;
  font-size: 16px;
}

.SubscriptionInfoModal_container .subFlex_one {
  flex: 1;
  font-size: 16px;
}
