.NotificationBadge {
  position: absolute;
  top: -5px;
  right: -9px;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #eb9a28;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #fff;
}
