.HorizontalWriters {
  width: 100%;
  min-height: 180px;
  position: relative;
}

.HorizontalWriters__Feedback {
  width: 50%;
  margin-inline: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.HorizontalWriters--mb-100 {
  margin-bottom: 100px;
}

.HorizontalWriters__Header {
  margin-bottom: 47px;
}

.HorizontalWriters__Header__Title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.7rem;
  display: inline-block;
  padding-inline-end: 20px;
  color: #265b9b;
  border-top: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
}
