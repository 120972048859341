.Modal {
  position: fixed;
  top: 0;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 20;
  width: 30%;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  height: auto;
  max-height: 600px;
  overflow: auto;
}

.Modal--Visible {
  visibility: visible;
  opacity: 1;
  top: 60px;
}

.Big_modal_flex_inline {
  width: 70%;
  display: flex;
  padding: 60px 70px;
}

/* responsive */

@media (max-width: 768px) {
  .Modal {
    width: 80%;
  }

  .Big_modal_flex_inline {
    width: 95%;
    display: initial;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Modal {
    width: 50%;
  }

  .Big_modal_flex_inline {
    width: 95%;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .Modal {
    width: 40%;
  }

  .Big_modal_flex_inline {
    width: 95%;
  }
}

/* @media (min-width: 1280px) and (max-width: 1919px) {
  .Modal {
    width: 30%;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .Container {
    max-width: 1480px;
    grid-template-columns: 310px auto 310px;
  }
  .Container > aside:last-child {
    display: block;
  }
  .MainContent {
    margin-inline: 20px;
  }
} */
