.BookCloudApps {
  display: flex;
  flex-direction: row;
  width: 90px;
}

.BookCloudApps__Store img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.BookCloudApps__AppStore {
  width: 32px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}

.BookCloudApps__PlayStore {
  width: 28px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .BookCloudApps__AppStore {
    width: 29px;
    height: 80%;
  }

  .BookCloudApps__PlayStore {
    width: 25px;
    height: 80%;
  }

  .BookCloudApps {
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .BookCloudApps {
    justify-content: center;
    margin: 0 auto;
  }
}
