.Comment__Writer {
  display: flex;
  align-items: center;
  gap: 27px;
  margin-bottom: 6px;
}

.Comment__Writer__Badge {
  width: 46px;
  height: 46px;
}

.Comment__Writer__Name {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  color: #35b8a3;
}

.Comment__Text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
  margin-bottom: 44px;
}

.Comment__Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 43px;
}

.Comment__Footer__Date {
  font-size: 1.4rem;
  font-weight: 600;
  color: #7f7f83;
}

.Comment__Footer__Replies {
  display: flex;
  gap: 4px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #7f7f83;
}

.Comment__Footer__Replies:hover {
  text-decoration: underline;
  color: #35b8a3;
}

/* replies */

.CommentReplies {
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  margin-bottom: 20px;
}

.CommentReplies__List {
  margin-inline-start: 80px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #aaa8a8;
  margin-bottom: 10px;
}

.CommentReplies__List__Item:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
}

.Reply__Writer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 4px;
}

.Reply__Writer__Badge {
  width: 26px;
  height: 26px;
}

.Reply__Writer__Name {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: #35b8a3;
}

.Reply__Text {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.1rem;
  margin-bottom: 10px;
}

.Reply__Date {
  width: fit-content;
  margin-inline-start: auto;
  font-size: 1rem;
  font-weight: 600;
  color: #7f7f83;
}

.CommentReplies__Control {
  display: flex;
  justify-content: flex-end;
}

.CommentReplies__Control__Hide {
  font-size: 1.2rem;
  color: #a33a3a;
  padding: 8px;
  line-height: 1rem;
}

.CommentReplies__Control__Hide:hover {
  background-color: #ffe0e0;
}
