.BookDetails {
  position: relative;
  display: flex;
  gap: 36px;
  margin-bottom: 72px;
  margin-top: 40px;
}

.BookDetails__Book {
  flex-shrink: 0;
  width: 200px;
}

.BookDetails__Details {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: -20px;
}

.BookDetails__Details__Header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.BookDetails__Details__Header__text_Container {
  width: 75%;
}

.BookDetails__Details__Header__Rating_Container {
  margin-top: 15px;
}

.BookDetails__Details__Header__Classifications {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px;
}
.BookDetails__Details__Header__Classifications__Classification {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 3rem;
  color: #d9d9d9;
  display: flex;
  align-items: center;
}

.BookDetails__Details__Header__Classifications__Classification:not(
    :first-child
  ) {
  margin-right: -8px;
}

.BookDetails__Details__Header__Classifications__Classification:not(
    :last-child
  )::after {
  content: "|";
  color: transparent;
  background-color: #d9d9d9;
  width: 1px;
  height: 12px;
  margin-inline-start: 7px;
}

.BookDetails__Details__Header__Title {
  color: #003169;
  margin-bottom: 4px;
  font-weight: bold;
  margin-top: 5px;
  line-height: 25px;
}

.BookDetails__Details__Header__Author {
  display: flex;
  align-items: center;
  gap: 14px;
}

.BookDetails__Details__Header__Author__Badge {
  width: 46px;
  height: 46px;
}

.BookDetails__Details__Header__Author__Name {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.8rem;
  color: #35b8a3;
  margin-top: 4px;
}

.BookDetails__Details__Header__ReviewText {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 7px;
}

.BookDetails__Details__About {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.BookDetails__Details__Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Book__Favourite_Share_Container {
  display: flex;
  align-items: center;
}

.BookActions__Action_Share {
  margin-right: 16px;
  position: relative;
  top: 3px;
}

.BookActions__Action_Report {
  color: #e12929;
  font-weight: bold;
  font-size: 15px;
  border: 2px solid;
  border-radius: 15px;
  padding: 7px 13px;
}

.BookActions__Action_Report_img {
  vertical-align: middle;
  margin-right: 10px;
}

.Book_BookCloudPlus_available {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 4px 20px;
  background-color: #eb9a28;
  border-radius: 10px;
}

.Book__Favourite img {
  border-left: 2px solid #000;
}

/* responsive */

@media (max-width: 500px) {
  .BookDetails {
    flex-direction: column;
    gap: 10px;
  }
  .BookDetails__Book {
    margin-inline: auto;
  }
  .BookDetails__Details__Header__text_Container {
    width: 100%;
  }
  .BookDetails__Details__Header__Title {
    text-align: center;
  }
  .BookDetails__Details__Header__Classifications,
  .BookDetails__Details__Header__Author,
  .Book__Favourite_Share_Container {
    justify-content: center;
  }
  .BookDetails__Details__Header__Rating_Container {
    margin: 15px auto 0;
  }
  .BookDetails__Details__About {
    text-align: center;
  }
  .Book_BookCloudPlus_available {
    margin: 20px auto;
  }
  .BookActions__Action_Report {
    margin: 0 auto;
    display: block;
  }
}

@media (max-width: 768px) {
  .BookDetails__Details__Header {
    flex-direction: column;
    align-items: flex-start;
  }
  .BookDetails__Book {
    min-width: 25%;
    flex-shrink: 0;
  }
  .BookDetails__Details__Footer {
    position: initial;
    display: block;
  }
  .Book_BookCloudPlus_available {
    margin-bottom: 20px;
    display: block;
    width: 70%;
    text-align: center;
  }

  .BookDetails__Details {
    margin-right: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .BookDetails__Details__Header {
    flex-direction: row;
    align-items: flex-end;
  }
  .BookDetails__Book {
    min-width: auto;
    flex-shrink: 0;
  }
  .BookDetails__Details__Footer {
    flex-direction: row;
    align-items: center;
    gap: 0px;
  }
  .BookDetails__Details__About {
    margin-bottom: 59px;
  }
}
