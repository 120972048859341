.ReportForm {
  display: block;
  width: 100%;
}

.ReportForm__Actions {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid #dfdcd7;
}

.ReportForm__Actions__Submit,
.ReportForm__Actions__Cancel {
  flex-shrink: 0;
}
