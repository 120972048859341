.TopNavMenu {
  float: right;
}
.TopNavMenu__List {
  display: flex;
  align-items: center;
}
.TopNavMenu__List__Item {
  display: flex;
  align-items: center;
}

.TopNavMenu__List__Item:not(:last-child)::after {
  content: "|";
  color: transparent;
  width: 1px;
  height: 43px;
  background-color: #bcbbcd;
}

.TopNavMenu__List__Item__Link {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 4.1rem;
  color: #bcbcbd;
  margin-inline: 40px;
  text-shadow: -1px 4px 4px rgba(188, 188, 189, 1);
}

.TopNavMenu__List__Item__Link--active {
  font-size: 2rem;
  font-weight: 800;
  line-height: 4.9rem;
  margin-inline: 20px;
}

/* responsive */

@media (max-width: 768px) {
  .TopNavMenu {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .TopNavMenu {
    display: block;
    width: 500px;
  }
  .TopNavMenu__List__Item__Link {
    font-size: 1.1rem;
    margin-inline: 14px;
  }
  .TopNavMenu__List__Item__Link--active {
    font-size: 1.3rem;
    margin-inline: 10px;
  }
  .TopNavMenu__List__Item:not(:last-child)::after {
    height: 16px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .TopNavMenu__List__Item__Link {
    font-size: 1.3rem;
    margin-inline: 20px;
  }
  .TopNavMenu__List__Item__Link--active {
    font-size: 1.6rem;
    margin-inline: 16px;
  }
  .TopNavMenu__List__Item:not(:last-child)::after {
    height: 25px;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .TopNavMenu__List__Item__Link {
    font-size: 1.8rem;
    margin-inline: 20px;
  }
  .TopNavMenu__List__Item__Link--active {
    font-size: 2rem;
    margin-inline: 18px;
  }
  .TopNavMenu__List__Item:not(:last-child)::after {
    height: 30px;
  }
}
