.AddBookForm {
  display: block;
  width: 100%;
}
.AddBookForm__ConvertButton button {
  width: auto;
  border-radius: 9px;
  padding: 8px 18px 9px;
}

.AddBookForm input,
.AddBookForm textarea {
  font-size: 16px;
  border: 2px solid #d9d9d9;
  border-radius: 6px;
}

.AddBookForm input::placeholder,
.AddBookForm textarea::placeholder {
  color: #b3b3b3;
}

.AddBookForm input:focus,
.AddBookForm textarea:focus {
  border-color: #9e9696;
}

.AddBookForm .checkbox_input {
  width: auto !important;
}

.AddBookForm .input_control_container {
  margin-bottom: 24px;
}

.AddBookForm label {
  color: #b3b3b3 !important;
}

.AddBookForm .inline_half_container {
  display: flex;
}

.AddBookForm .inline_half_child {
  flex: 1;
}

.AddBookForm .inline_half_child label {
  display: inline-block;
  margin-right: 11px;
  vertical-align: initial;
}

.AddBookForm .inline_half_child_two {
  flex: 2;
}

.AddBookForm .inline_half_child_three {
  flex: 3;
}

.AddBookForm .inline_half_child_right {
  margin-right: 80px;
}

.inline_half_child_right_small {
  margin-right: 10px;
}

.uploadedBookImage_container {
  height: 290px;
  flex: 1;
  border: 1px solid #d9d9d9;
  text-align: center;
  margin-left: 20px;
  padding: 10px;
}

.uploadedBookImage_container img {
  vertical-align: middle;
  height: 100%;
}

.uploadedBook_container {
  width: 100%;
  flex: 3;
}

.uploadedBookImage_title {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: lighter;
  line-height: 290px;
}

/* responsive */

@media (max-width: 768px) {
  .AddBookForm .inline_half_container {
    display: initial;
  }

  .AddBookForm .inline_half_child_right {
    margin-right: 0px;
  }

  .AddBookForm .inline_half_child {
    margin-bottom: 24px;
  }

  .uploadedBookImage_container {
    width: 200px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1280px) {
}

@media (min-width: 1280px) and (max-width: 1536px) {
}
