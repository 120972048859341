.AuthorBadge {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.AuthorBadge__AuthorImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.AuthorBadge__Premium {
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 48px;
  height: 42px;
  object-fit: cover;
  object-position: center;
}

.AuthorBadge__Premium--small {
  width: 25px;
  height: 22px;
}

.AuthorBadge__Edit {
  display: inline-block;
  color: #fff;
  width: 42px;
  height: 42px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #169fda 0%, #35b8a3 78%);
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.6rem;
}

/* responsive */

@media (max-width: 768px) {
  .AuthorBadge__Premium {
    width: 30px;
    height: 26px;
  }
  .AuthorBadge__Premium--small {
    width: 20px;
    height: 22px;
  }
  .AuthorBadge__Edit {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 768px) and (max-width: 1536px) {
  .AuthorBadge__Premium {
    width: 36px;
    height: 30px;
  }
  .AuthorBadge__Premium--small {
    width: 26px;
    height: 24px;
  }
  .AuthorBadge__Edit {
    width: 38px;
    height: 38px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .AuthorBadge__Premium {
    width: 40px;
    height: 36px;
  }
  .AuthorBadge__Premium--small {
    width: 26px;
    height: 24px;
  }
  .AuthorBadge__Edit {
    width: 42px;
    height: 42px;
  }
}
