.BooksGrid {
  margin-bottom: 24px;
}

.BooksGrid__Grid {
  position: relative;
  min-height: 400px;
  margin-bottom: 77px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 64px;
}

.BooksGrid__Ad {
  grid-column: 1/-1;
  grid-row: 3;
}

/* responsive */

@media (max-width: 500px) {
  .BooksGrid__Grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 64px 16px;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .BooksGrid__Grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 16px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .BooksGrid__Grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 16px;
  }
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .BooksGrid__Grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 16px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .BooksGrid__Grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 64px 16px;
  }
}
